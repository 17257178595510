import React from 'react';
import ReactMarkdown from 'markdown-to-jsx';

import Statistics from '@shipae/components-sandbox/component/statistics';
import StatisticsTheme
  from '@shipae/components-sandbox/component/statistics/theme';

import {
  HalfContentHalfWidget as IProps,
} from 'infrastructure/gatsby/types/half-content-half-widget';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';
import { fonts, media } from 'src/views/theme';
import gloabalReactMdOptions from '../shared/react-makdown-options';

const Section = styled.section`
  position: relative;
  padding: 9rem calc((100% - var(--container-width)) / 2);
  overflow: hidden;
`;

interface BackgroundImageProps {
  horizontalPosition: 'left' | 'right';
}

const BackgroundImage = styled.div<BackgroundImageProps>`
  position: absolute;
  /* Using pixels for design precision */
  top: -20px;
  right: -200px;
  height: 903px;
  width: 903px;

  ${ media.max(881) } {
    right: -685px;
  }

  ${ media.sm } {
    top: 120px;
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 2;
`;

interface LeftColumnProps {
  horizontalPosition: 'left' | 'right';
}

const LeftColumn = styled.div<LeftColumnProps>`
  max-width: 68.8rem;
`;

const Heading = styled.h2`
  ${ fonts.displaym200 }
  margin-bottom: 3.8rem;

  ${ media.sm } {
    text-align: center;
  }
`;

const Description = styled.div`
  h3 {
    ${ fonts.displayxs400 }
    margin-top: 3.3rem;

    ${ media.sm } {
      margin-top: 5.3rem;
      margin-bottom: 1rem;
    }
  }

  &, p {
    ${ fonts.bodyl200 }

    ${ media.sm } {
      ${ fonts.displayxs200 }
    }
  }
`;

const StatsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 7rem;
  align-content: center;
  & > * {
    margin-right: 6.5rem;
    margin-bottom: 4rem;
  }
`;

const statisticsTheme = {
  ...StatisticsTheme,
  Title: styled(StatisticsTheme.Title).attrs({ as: 'h3' })``,
  Description: styled(StatisticsTheme.Description)`
    p {
      margin: 0;
      font-size: 4.5rem;
      font-weight: 200;
      line-height: 6.3rem;

      ${ media.sm } {
        font-size: 4.2rem;
      }
    }
  `,
};

const HalfContentHalfWidget: React.FC<IProps> = ({
  sectionId,
  title,
  description,
  statistics,
  contentPosition,
  fallbackImage,
}) => (
  <Section id={sectionId}>
    <BackgroundImage
      horizontalPosition={contentPosition === 'left' ? 'right' : 'left'}
    >
      {fallbackImage.localFile?.childImageSharp?.fluid ? (
        <GatsbyImage
          fluid={fallbackImage.localFile?.childImageSharp?.fluid}
          alt={fallbackImage.alternativeText || ''}
        />
      ) : null}
    </BackgroundImage>
    <Content>
      <LeftColumn
        horizontalPosition={contentPosition || 'left'}
      >
        <Heading>
          {title}
        </Heading>
        <Description>
          <ReactMarkdown options={gloabalReactMdOptions}>
            {description || ''}
          </ReactMarkdown>
        </Description>
      </LeftColumn>
      <StatsContainer>
        {statistics?.map((stat) => (
          <Statistics
            key={stat.id}
            title={stat.title}
            description={(
              <ReactMarkdown
                options={gloabalReactMdOptions}
              >
                {stat.content || ''}
              </ReactMarkdown>
            )}
            color={stat.hexColor}
            theme={statisticsTheme}
          />
        ))}
      </StatsContainer>
    </Content>
  </Section>
);

export default HalfContentHalfWidget;
